import React from "react";

const Checkbox = (props) => {
  return (
    <div className="Checkbox">
      <input
        key={props.id}
        onClick={props.handleCheckboxChange}
        type="checkbox"
        checked={props.isChecked}
        value={props.value}
      />
      <label htmlFor={props.id}>{props.value}</label>
    </div>
  );
};

export default Checkbox;
