import React from "react";
import RadioButton from "./RadioButton";
import { withTranslation, Trans } from "react-i18next";

class Department extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.back = this.back.bind(this);
    this.skip = this.skip.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleCheck(event) {
    this.setState({
      checked: event.target.value,
    });

    //global variables

    const selectedDpt = event.target.value;
    const { departments } = this.props;

    {
      Object.keys(departments).map((key) => {
        if (selectedDpt == departments[key].id) {
          window.$departments = departments[key];
          window.$departmentId = departments[key].id;
          console.log(departments[key].attending_users.length);
          if (departments[key].attending_users.length > 0) {
            this.props.nextStep();
          } else {
            this.props.skipStep();
          }
        }
      });
    }
  }

  back() {
    this.props.prevStep();
  }

  skip() {
    this.props.nextStep();
  }

  mainView = () => {
    const { t } = this.props;
    const { departments } = this.props;
    return (
      <div className="Department">
        <div className="inner-wrapper">
          <h1>{t("department.title")}</h1>
          <div className="form d-flex Department-screen">
            {Object.keys(departments).map((key) => (
              <RadioButton
                name="department"
                changed={this.handleCheck}
                id={departments[key].id}
                isSelected={this.state.checked === 0}
                data1={departments[key].name}
                value={departments[key].id}
                label={
                  <img src={`/image/${departments[key].icon}.png`} alt="" />
                }
              />
            ))}
            {/* <div className="nav-button-skip">
              <button name="skip" onClick={this.skip}>
                {t("button.skip")}
              </button>
            </div> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          className={"back-btn"}
          onClick={this.back}
        >
          <img
            alt=""
            src="image/arrow-left.svg"
            style={{ height: 15, width: 15, marginRight: 10 }}
          />
          {t("button.back")}
        </div>
      </div>
    );
  };

  render() {
    return this.mainView();
  }
}

export default withTranslation()(Department);
