import React from "react";

const RadioButton = (props) => {
    return (
        <div className="RadioButton">
            <input
                className="input-hidden"
                data={props.data1}
                id={props.id}
                onChange={props.changed}
                value={props.value}
                type="radio"
                checked={props.isSelected}
            />
            <label htmlFor={props.id}>
                {props.label} {props.data1}
            </label>
        </div>
    );
};

export default RadioButton;
