import React from "react";
import ReactDOM from "react-dom";
import "./styles.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import MultiStepForm from "./MultiStepForm";

function App() {
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const divisionId = urlParams.get("divisionId");
    const companyId = urlParams.get("companyId");
    const qrId = urlParams.get("qrId");
    const updatedDivisionId = divisionId && divisionId.replace(/[^\w\s]/gi, "");

    localStorage.setItem("divisionId", Number(updatedDivisionId));
    localStorage.setItem("companyId", Number(companyId));

    localStorage.setItem("qrId", qrId || "");
  }, []);
  return (
    <div className="app">
      <div className="wrapper">
        <MultiStepForm />
      </div>

      <div className="footer-logo">
        <img src="image/sl_logo_darkPurpl.png" alt="" />
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  rootElement
);
