import React from "react";
import RadioButton from "./RadioButton";
import { withTranslation, Trans } from "react-i18next";
import { isMobile } from "react-device-detect";

class Recommend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      extra_feedback_questions: [],
      loadQuestions: false,
    };
    this.handleCheck = this.handleCheck.bind(this);
  }
  handleCheck(event) {
    window.$recommend = event.target.value;
    this.setState({
      checked: event.target.value,
    });
    this.props.nextStep();
  }

  componentDidMount() {
    let data = localStorage.getItem("extra_feedback_questions");
    let parsedData = JSON.parse(data);
    this.setState({
      extra_feedback_questions: isMobile ? parsedData.reverse() : parsedData,
      loadQuestions: true,
    });
    window.scrollTo(0, 0);
  }

  renderQuestion = () => {
    return (
      this.state.loadQuestions &&
      this.state.extra_feedback_questions.map((res) => {
        return (
          <div className="likely_L">
            <RadioButton
              name="recommend"
              changed={this.handleCheck}
              id={res.id}
              isSelected={this.state.checked === 0}
              value={res.id}
              label={res.name}
            />
          </div>
        );
      })
    );
  };

  render() {
    const { t } = this.props;

    return (
      <div className="Recommend">
        <div className="inner-wrapper">
          <h1>{t("recommend.title")} </h1>
          <div className="form d-flex Recommend-rating">
            <div className="very-like">
              <font>{t("recommend.upmsg")} </font>
            </div>
            {this.renderQuestion()}
            <div className="like">
              <font> {t("recommend.downmsg")} </font>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Recommend);
