import React from "react";
import RadioButton from "./RadioButton";
import { withTranslation, Trans } from "react-i18next";

class Helped extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      checked: true,
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.back = this.back.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleCheck(event) {
    this.setState({
      checked: event.target.value,
    });

    window.$selecteduser = event.target.value;
    this.props.nextStep();
  }

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  skip = (e) => {
    e.preventDefault();
    this.props.skipStep();
  };

  departments = window.$departments;

  mainView = () => {
    const attending_users = window.$attending_users;
    const elements = [];
    const { t } = this.props;

    if (this.departments) {
      const dptusers = this.departments.attending_users;
      return (
        <div className="Helped">
          <div class="inner-wrapper">
            <h1> {t("helped.title")}</h1>
            <div className="form d-flex Helped-service">
              {Object.keys(dptusers).map((key) => (
                <RadioButton
                  name="helped"
                  changed={this.handleCheck}
                  id={dptusers[key].id}
                  isSelected={this.state.checked === 0}
                  data1={dptusers[key].name}
                  value={dptusers[key].id}
                  label={
                    <img
                      alt=""
                      src={
                        dptusers[key].avatar.url
                          ? dptusers[key].avatar.url
                          : "image/avatar-missing@2x.png"
                      }
                    />
                  }
                />
              ))}

              <div class="nav-button-skip">
                <button name="skip" onClick={this.skip}>
                  {t("button.skip")}
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className={"back-btn"}
            onClick={this.back}
          >
            <img
              alt=""
              src="image/arrow-left.svg"
              style={{ height: 15, width: 15, marginRight: 10 }}
            />
            {t("button.back")}
          </div>
        </div>
      );
    } else {
      return (
        <div className="Helped">
          <div class="inner-wrapper">
            <h1> {t("helped.title")}</h1>
            <div className="form d-flex Helped-service">
              {Object.keys(attending_users).map((key) => (
                <RadioButton
                  name="helped"
                  changed={this.handleCheck}
                  id={attending_users[key].id}
                  isSelected={this.state.checked === 0}
                  data1={attending_users[key].name}
                  value={attending_users[key].id}
                  label={
                    <img
                      alt=""
                      src={
                        attending_users[key].avatar.url
                          ? attending_users[key].avatar.url
                          : "image/avatar-missing@2x.png"
                      }
                    />
                  }
                />
              ))}

              <div class="nav-button-skip">
                <button name="skip" onClick={this.skip}>
                  {t("button.skip")}
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className={"back-btn"}
            onClick={this.back}
          >
            <img
              alt=""
              src="image/arrow-left.svg"
              style={{ height: 15, width: 15, marginRight: 10 }}
            />
            {t("button.back")}
          </div>
        </div>
      );
    }
  };

  render() {
    return this.mainView();
  }
}

export default withTranslation()(Helped);
