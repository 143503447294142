import React from "react";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import i18n from "./i18n";
import DownArrow from "./assests/down-arrow-lan.png";
import Check from "./assests/check-in.png";

class CompanyStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      checked: true,
      showDropDown: false,
      selectedOption: null
    };
  }

  saveAndContinue = e => {
    e.preventDefault();
    const divisionUrl = "?divisionId=" + this.state.selectedOption.value;
    console.log("url:", divisionUrl);
    window.location = divisionUrl;
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  changeLanguage = lng => {
    this.setState({ showDropDown: false });
    i18n.changeLanguage(lng);
    window.location.reload(true);
  };

  mainView = () => {
    const { t, companyInfo, isStaging } = this.props;
    const { selectedOption } = this.state;
    let boxClass = ["box"];
    let divisions = this.props.divisions;
    let options = divisions.map(item => {
      return { value: item.id, label: item.name };
    });

    const colourStyles = {
      control: styles => ({ ...styles, backgroundColor: "#ebece9", borderRadius: "6px" }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          opacity: 3,
          backgroundColor: isDisabled
            ? null
            : isSelected
              ? "#eeeff2"
              : isFocused
                ? "transparent"
                : null,
          color: "black",
          textAlign: "left"
        };
      }
    };

    let button;
    if (selectedOption) {
      button = (
        <button
          onClick={this.saveAndContinue}
          className={boxClass.join(" ")}
          name="next"
        >
          {t("button.next")}
        </button>
      );
    }
    const companyImg = (companyInfo && companyInfo.avatar)
      ? isStaging
        ? companyInfo.avatar.large.url.replace('dashboard.servicelovers', 'staging-ror.servicelovers')
        : companyInfo.avatar.large.url
      : null;
    return (
      <React.Fragment>
        <div className="change-lang">
          <div>
            <div className="change-lang-main">
              <p
                style={{ fontSize: 15, marginRight: 7, cursor: "pointer" }}
                onClick={() =>
                  this.setState({ showDropDown: !this.state.showDropDown })
                }
              >
                {i18n.language.toUpperCase() === "EN-GB" ||
                  i18n.language === "en"
                  ? "EN"
                  : "DA"}
              </p>
              <img
                onClick={() =>
                  this.setState({ showDropDown: !this.state.showDropDown })
                }
                style={{ marginTop: 20, cursor: "pointer" }}
                src={DownArrow}
                alt="down"
                width="12"
                height="12"
              ></img>
            </div>
            {this.state.showDropDown && (
              <div className="drop-down-content">
                <div style={{ height: 82 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: 30,
                      cursor: "pointer"
                    }}
                  >
                    {i18n.language.toUpperCase() === "DK-DA" ||
                      i18n.language === "da" ? (
                        <img
                          style={{
                            marginTop: 20,
                            marginLeft: 10,
                            marginRight: 10
                          }}
                          src={Check}
                          alt="down"
                          width="12"
                          height="12"
                        ></img>
                      ) : (
                        <div
                          style={{
                            marginTop: 20,
                            height: 12,
                            width: 12,
                            marginLeft: 10,
                            marginRight: 10
                          }}
                        />
                      )}
                    <p
                      style={{ fontSize: 13, marginTop: 18 }}
                      onClick={() => this.changeLanguage("da")}
                    >
                      DA
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: 30,
                      cursor: "pointer"
                    }}
                  >
                    {i18n.language.toUpperCase() === "EN-GB" ||
                      i18n.language === "en" ? (
                        <img
                          style={{
                            marginTop: 20,
                            marginLeft: 10,
                            marginRight: 10
                          }}
                          src={Check}
                          alt="down"
                          width="12"
                          height="12"
                        ></img>
                      ) : (
                        <div
                          style={{
                            marginTop: 20,
                            height: 12,
                            width: 12,
                            marginLeft: 10,
                            marginRight: 10
                          }}
                        />
                      )}
                    <p
                      onClick={() => this.changeLanguage("en")}
                      style={{ fontSize: 13, marginTop: 18 }}
                    >
                      EN
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="inner-wrapper-rating division-wrapper-container">
          <img src={companyImg} style={{ width: '25%', marginBottom: 30 }} />
          <h1>{t("division.title")}</h1>
          <div className="form d-flex d-button">
            <Select
              styles={colourStyles}
              value={selectedOption}
              className="divisionSelect"
              placeholder={t("division.placeholder")}
              onChange={this.handleChange}
              options={options}
            />
          </div>

          <div className="nav-button">{button}</div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return this.mainView();
  }
}

export default withTranslation()(CompanyStep);
