import React from "react";
import axios from "axios";
import i18n from "./i18n";
import Names from "./Names";
import Negative from "./Negative";
import Recommend from "./recommend";
import Department from "./department";
import Helped from "./helped";
import Service from "./service";
import Thankyou from "./thankyou";
import CompanyStep from "./CompanyStep";
import Loader from "react-loader-spinner";
import { withTranslation, Trans } from "react-i18next";

import "./styles.css";

const isStaging = false;
const baseURLProd = isStaging
  ? "https://stage-ror.servicelover.com"
  : "https://www.servicelovers.com";

class MultiStepForm extends React.Component {
  state = {
    step: 1,
    coinList: [],
    rating: "",
    responsetime: "",
    unhelpful: "",
    unprofessional: "",
    Notexpected: "",
    value: "en",
    loading: true,
    companyStep: false,
    divisions: [],
    companyInfo: null,
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  skipStep = () => {
    const { step } = this.state;
    if (step === 2) {
      this.setState({
        step: step + 4,
      });
    } else {
      this.setState({
        step: step + 2,
      });
    }
  };

  prevStep = () => {
    const { step } = this.state;
    if (step === 3 && this.state.coinList.use_departments === false) {
      this.setState({
        step: step - 2,
      });
    } else if (step === 4) {
      if (this.state.coinList.use_departments === true) {
        this.setState({
          step: step - 2,
        });
      } else {
        this.setState({
          step: step - 3,
        });
      }
    } else {
      this.setState({
        step: step - 1,
      });
    }
  };

  /* get API Call */

  componentDidMount() {
    i18n.language.toUpperCase() === "EN-GB" && i18n.changeLanguage("da");

    setTimeout(() => {
      const divId = Number(localStorage.getItem("divisionId"));
      const companyId = Number(localStorage.getItem("companyId"));

      if (companyId) {
        this.setState({ loading: true, companyStep: true });
        axios({
          method: "get",
          url:
            baseURLProd +
            "/store_api/v1/companies/" +
            companyId +
            "/for_web_rating",
          headers: {
            "Accept-Language": i18n.language === "en" ? "en-GB" : "da-DA",
          },
        }).then((res) => {
          const coins = res.data;

          if (coins.divisions) {
            this.setState({
              loading: false,
              divisions: coins.divisions,
              companyInfo: coins,
            });
          } else {
            alert(
              "Ooops! This companyId doesn't have any divisions, Try again with valid companyId"
            );
          }
        });
      } else if (divId) {
        this.setState({ loading: true, companyStep: false });
        axios({
          method: "get",
          url:
            baseURLProd +
            "/store_api/v1/divisions/" +
            divId +
            "/for_web_rating",
          headers: {
            "Accept-Language": i18n.language === "en" ? "en-GB" : "da-DA",
          },
        }).then((res) => {
          const coins = res.data;

          localStorage.setItem(
            "extra_feedback_questions",
            JSON.stringify(res.data.company.extra_feedback_questions)
          );

          let {
            rating_1_to_3_redirect_url,
            rating_4_redirect_url,
            rating_5_redirect_url,
          } = coins;

          if (
            rating_1_to_3_redirect_url &&
            !rating_1_to_3_redirect_url.includes("http")
          ) {
            rating_1_to_3_redirect_url = `http://${rating_1_to_3_redirect_url}`;
          }
          if (
            rating_4_redirect_url &&
            !rating_4_redirect_url.includes("http")
          ) {
            rating_4_redirect_url = `http://${rating_4_redirect_url}`;
          }
          if (
            rating_5_redirect_url &&
            !rating_5_redirect_url.includes("http")
          ) {
            rating_5_redirect_url = `http://${rating_5_redirect_url}`;
          }

          const redirectURLs = {
            rating_1_to_3_redirect_url:
              rating_1_to_3_redirect_url || "https://servicelovers.com/",
            rating_4_redirect_url:
              rating_4_redirect_url || "https://servicelovers.com/",
            rating_5_redirect_url:
              rating_5_redirect_url || "https://servicelovers.com/",
          };
          localStorage.setItem("redirectURLs", JSON.stringify(redirectURLs));
          if (coins.company) {
            this.setState({ coinList: coins, loading: false });
          } else {
            alert(
              "Ooops! This divisionId doesn't belong to any company, Try again with valid divisionId"
            );
          }
        });
      } else {
        alert("No divisionId/companyId provided");
      }
    }, 1000);
  }

  /* End get API call */

  handleChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  mainView = (props) => {
    const { step, coinList } = this.state;
    const { rating, reasons } = this.state;
    const { divisions, companyStep, companyInfo } = this.state;
    const values = { rating, reasons };

    const get_users = this.state.coinList.attending_users;
    const get_departments = this.state.coinList.use_departments;
    //global variables
    window.$attending_users = get_users;
    window.$company = this.state.coinList.company;
    const ratingvalue = window.$ratingvalue;

    switch (step) {
      case 1:
        if (companyStep === true) {
          return (
            <CompanyStep
              divisions={divisions}
              isStaging={isStaging}
              companyInfo={companyInfo}
            />
          );
        } else {
          return (
            <Names
              values={values}
              isStaging={isStaging}
              nextStep={this.nextStep}
              divisionInfo={coinList.company}
              handleChange={this.handleChange}
            />
          );
        }

      case 2:
        if (this.state.coinList.use_departments === true) {
          const departments = this.state.coinList.departments;
          return (
            <Department
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              skipStep={this.skipStep}
              handleChange={this.handleChange}
              values={values}
              departments={departments}
            />
          );
        } else {
          this.nextStep();
        }
      case 3:
        if (ratingvalue <= 3) {
          return (
            <Negative
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={values}
            />
          );
        } else {
          this.nextStep();
        }
      case 4:
        if (ratingvalue >= 4 && get_departments === true) {
          return (
            <Helped
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              skipStep={this.skipStep}
              handleChange={this.handleChange}
              values={values}
            />
          );
        } else if (ratingvalue >= 4 && get_users.length > 0) {
          return (
            <Helped
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              skipStep={this.skipStep}
              handleChange={this.handleChange}
              values={values}
            />
          );
        } else {
          this.nextStep();
        }
      case 5:
        if (ratingvalue >= 4 && get_departments === true) {
          return (
            <Service
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={values}
            />
          );
        } else if (ratingvalue >= 4 && get_users.length > 0) {
          return (
            <Service
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={values}
            />
          );
        } else {
          this.nextStep();
        }
      case 6:
        if (this.state.coinList.use_extra_feedback === true) {
          return (
            <Recommend
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={values}
            />
          );
        } else {
          this.nextStep();
        }
      case 7:
        return (
          <Thankyou
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      default:
        return <></>;
    }
  };

  render() {
    const { loading } = this.state;
    const { t } = this.props;
    return loading === false ? (
      this.mainView()
    ) : (
      <div className={"inner-wrapper-rating"} style={{ alignSelf: "center" }}>
        <h1>{t("loading.title")}</h1>
        <Loader
          type="Puff"
          color="#32B1AE"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      </div>
    );
  }
}

export default withTranslation()(MultiStepForm);
