import React from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import { withTranslation, Trans } from "react-i18next";

class Thankyou extends React.Component {
  componentDidMount = () => {
    const divisionId = localStorage.getItem("divisionId") || null;
    if (!divisionId) window.location.reload();
    const ratingvalue = window.$ratingvalue;
    const reasons = window.$reasons;
    const feedback = window.$feedback || "";
    const recommend = window.$recommend ? [window.$recommend] : [];
    const getDepartment = window.$departmentId;
    let questionIds = [];
    let departmentId = "";
    const qrId = localStorage.getItem("qrId");
    const userComment =
      ratingvalue <= 3 && qrId ? `${qrId}: ${feedback}` : feedback;
    if (getDepartment) {
      departmentId = getDepartment;
    }
    if (reasons) {
      reasons.forEach((reason) => {
        if (reason.isChecked === true) questionIds.push(reason.id);
      });

      //Negative screen Post Data
      if (ratingvalue <= 3) {
        var data = JSON.stringify({
          div_reviews: [
            {
              review_type: "web",
              division_id: localStorage.getItem("divisionId"),
              department_id: departmentId,
              score: ratingvalue,
              uuid: uuidv4(),
              feedback_description: {
                content: userComment,
                division_id: localStorage.getItem("divisionId"),
                question_ids: questionIds,
                uuid: uuidv4(),
              },
              extra_feedback_description: {
                content: "Negative",
                division_id: localStorage.getItem("divisionId"),
                question_ids: recommend,
                uuid: uuidv4(),
              },
            },
          ],
        });
      } else {
        //Positive screen Post Data
        const selecteduser = window.$selecteduser;
        var data = JSON.stringify({
          div_reviews: [
            {
              review_type: "web",
              division_id: localStorage.getItem("divisionId"),
              department_id: departmentId,
              score: ratingvalue,
              uuid: uuidv4(),
              extra_feedback_description: {
                content: "Positive",
                division_id: localStorage.getItem("divisionId"),
                question_ids: recommend,
                uuid: uuidv4(),
              },
            },
          ],
          reviews: [
            {
              content: userComment,
              division_id: localStorage.getItem("divisionId"),
              department_id: departmentId,
              review_type: "web",
              employee_id: selecteduser,
              tag_ids: questionIds,
              rate: ratingvalue,
              uuid: uuidv4(),
            },
          ],
        });
      }
    } else {
      //default Post Data
      var data = JSON.stringify({
        div_reviews: [
          {
            review_type: "web",
            division_id: localStorage.getItem("divisionId"),
            department_id: departmentId,
            score: ratingvalue,
            uuid: uuidv4(),
            feedback_description: {
              content: userComment,
              division_id: localStorage.getItem("divisionId"),
              question_ids: questionIds,
              uuid: uuidv4(),
            },
            extra_feedback_description: {
              content: "",
              division_id: localStorage.getItem("divisionId"),
              question_ids: recommend,
              uuid: uuidv4(),
            },
          },
        ],
      });
    }
    console.log(data);

    /**** POST API call  **/
    var config = {
      method: "post",
      url:
        "https://dashboard.servicelovers.com/store_api/v1/flows/synchronize_from_web_rating",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    /* End POST API call */

    // Start counting when the page is loaded
    this.timeoutHandle = setTimeout(() => {
      // Add your logic for the transition

      //Get the redirect URLS from the localstorage

      const redirectURLs = JSON.parse(localStorage.getItem("redirectURLs"));
      const {
        rating_1_to_3_redirect_url,
        rating_4_redirect_url,
        rating_5_redirect_url,
      } = redirectURLs;

      let redirectTo =
        ratingvalue <= 3
          ? rating_1_to_3_redirect_url
          : ratingvalue < 5
          ? rating_4_redirect_url
          : rating_5_redirect_url;
      window.location.href = redirectTo;
    }, 3000);
  };

  mainView = () => {
    const { t } = this.props;
    const setScreen = window.$ratingvalue;
    if (setScreen <= 3) {
      return (
        <div className="Thankyou">
          <div className="inner-wrapper">
            <div className="d-flex justify-content-center align-items-center">
              <h1>{t("negative.thanksTitle")}</h1>
              <h4>{t("negative.thanksSubTitle")}</h4>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="Thankyou">
          <div className="inner-wrapper">
            <div className="d-flex justify-content-center align-items-center">
              <img
                style={{ marginBottom: 12 }}
                alt=""
                src="image/ico-thumb.png"
                className={"thankyouImage"}
              />
              <h1>{t("positive.thanksTitle")}</h1>
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    return this.mainView();
  }
}

export default withTranslation()(Thankyou);
