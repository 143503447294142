import React from "react";
import Checkbox from "./Checkbox";
import { withTranslation, Trans } from "react-i18next";

class Negative extends React.Component {
  constructor(props) {
    super(props);
    this.back = this.back.bind(this);
    this.state = { step: 5 };
    const company = window.$company;
    const use_extra_feedback = company && company.use_extra_feedback;

    const elements = [];
    company &&
      company.feedback_questions.forEach((index) => {
        elements.push({ id: index.id, value: index.name, isChecked: false });
      });
    this.state = {
      reasons: elements,
      use_extra_feedback: use_extra_feedback,
      feedback: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  handletextareaChange = (event) => {
    window.$feedback = event.target.value;
    this.setState({ feedback: event.target.value });
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  state = { checked: false };

  handleCheckboxChange = (event) => {
    let reasons = this.state.reasons;
    reasons.forEach((reason) => {
      if (reason.value === event.target.value)
        reason.isChecked = event.target.checked;
    });

    this.setState({ reasons: reasons, addClass: this.checkValidation() });
    window.$reasons = reasons;
  };

  checkValidation = () => {
    let checked = false;
    for (let i = 0; i < this.state.reasons.length; i++) {
      if (this.state.reasons[i].isChecked === true) {
        checked = true;
      }
    }
    if (checked === true) {
      return true;
    }
    return false;
  };

  mainView = () => {
    let boxClass = ["box"];
    const { t } = this.props;
    return (
      <div className="Negative">
        <div className="inner-wrapper">
          <h1>{t("negative.title")}</h1>
          <div className="form d-flex reason">
            {this.state.reasons.map((reason, index) => (
              <Checkbox
                handleCheckboxChange={this.handleCheckboxChange}
                {...reason}
              />
            ))}

            <textarea
              placeholder={t("placeholder.comment")}
              name="reason_feedback"
              className="inputtext"
              onChange={this.handletextareaChange}
              value={this.state.value}
              style={{ minHeight: 70, marginTop: 10 }}
            />

            {this.state.addClass || this.state.feedback !== "" ? (
              <div className="nav-button">
                <button
                  onClick={this.saveAndContinue}
                  className={boxClass.join(" ")}
                  name="next"
                >
                  {t("button.next")}
                </button>
              </div>
            ) : (
              <div className="nav-button-skip">
                <button
                  onClick={this.saveAndContinue}
                  className={boxClass.join(" ")}
                  name="skip"
                >
                  {t("button.skip")}
                </button>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          className={"back-btn"}
          onClick={this.back}
        >
          <img
            alt=""
            src="image/arrow-left.svg"
            style={{ height: 15, width: 15, marginRight: 10 }}
          />
          {t("button.back")}
        </div>
      </div>
    );
  };
  render() {
    return this.mainView();
  }
}

export default withTranslation()(Negative);
