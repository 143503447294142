import React from "react";
import ItemForm from "./ItemForm";
import Checkbox from "./Checkbox";
import Helped from "./helped";
import { withTranslation, Trans } from "react-i18next";

class Service extends React.Component {
  constructor(props) {
    super(props);
    this.back = this.back.bind(this);

    const attending_users = window.$attending_users;
    const selecteduser = window.$selecteduser;
    const departments = window.$departments;
    const elements = [];

    if (departments) {
      {
        const dptusers = departments.attending_users;
        Object.keys(dptusers).map((key) =>
          dptusers[key].tags.forEach((index) => {
            if (selecteduser == dptusers[key].id) {
              elements.push({
                id: index.id,
                value: index.name,
                isChecked: false,
              });
            }
          })
        );
      }
    } else {
      {
        Object.keys(attending_users).map((key) =>
          attending_users[key].tags.forEach((index) => {
            if (selecteduser == attending_users[key].id) {
              elements.push({
                id: index.id,
                value: index.name,
                isChecked: false,
              });
            }
          })
        );
      }
    }

    this.state = {
      services: elements,
      feedback: "",
      checked: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  skip = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  handlefeedbackChange = (event) => {
    window.$feedback = event.target.value;
    this.setState({ feedback: event.target.value });
  };

  checkValidation = () => {
    let checked = false;
    for (let i = 0; i < this.state.services.length; i++) {
      if (this.state.services[i].isChecked === true) {
        checked = true;
      }
    }
    if (checked === true) {
      return true;
    }
    return false;
  };

  handleCheckboxChange = (event) => {
    let services = this.state.services;
    services.forEach((servicesname) => {
      if (servicesname.value === event.target.value)
        servicesname.isChecked = event.target.checked;
    });
    this.setState({ services: services, addClass: this.checkValidation() });
    window.$reasons = services;
  };

  mainView = () => {
    let boxClass = ["next"];
    const { values } = this.props;
    const attending_users = window.$attending_users;
    const selecteduser = window.$selecteduser;
    const departments = window.$departments;
    const { t } = this.props;

    if (departments) {
      const dptusers = departments.attending_users;
      return (
        <div className="Service">
          <div className="inner-wrapper">
            <div className="attendee-wrapper">
              {Object.keys(dptusers).map((key) => (
                <div>
                  {selecteduser == dptusers[key].id ? (
                    <img
                      src={
                        dptusers[key].avatar.url
                          ? dptusers[key].avatar.url
                          : "image/avatar-missing@2x.png"
                      }
                      alt=""
                    />
                  ) : null}
                  {selecteduser == dptusers[key].id ? (
                    <div> {dptusers[key].name} </div>
                  ) : null}
                </div>
              ))}
            </div>
            <h1 style={{ marginBottom: 0 }}>{t("service.title")}</h1>
            <p style={{ marginTop: 12, marginBottom: 30 }}>
              {t("service.select_as_many")}
            </p>
            <div className="form d-flex Service-rating">
              {this.state.services.map((servicesname) => {
                return (
                  <Checkbox
                    handleCheckboxChange={this.handleCheckboxChange}
                    {...servicesname}
                  />
                );
              })}

              <textArea
                placeholder={t("placeholder.service")}
                className="inputtext"
                onChange={this.handlefeedbackChange}
                value={this.state.value}
                style={{ minHeight: 70 }}
              />

              {this.state.addClass || this.state.feedback !== "" ? (
                <div className="nav-button">
                  <button
                    onClick={this.saveAndContinue}
                    className={boxClass.join(" ")}
                    name="next"
                  >
                    {t("button.next")}
                  </button>
                </div>
              ) : (
                <div className="nav-button-skip">
                  <button
                    onClick={this.saveAndContinue}
                    className={boxClass.join(" ")}
                    name="next"
                  >
                    {t("button.skip")}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className={"back-btn"}
            onClick={this.back}
          >
            <img
              alt=""
              src="image/arrow-left.svg"
              style={{ height: 15, width: 15, marginRight: 10 }}
            />
            {t("button.back")}
          </div>
        </div>
      );
    } else {
      return (
        <div className="Service">
          <div className="inner-wrapper">
            <div className="attendee-wrapper">
              {Object.keys(attending_users).map((key) => (
                <div>
                  {selecteduser == attending_users[key].id ? (
                    <img src={attending_users[key].avatar.url} alt="" />
                  ) : null}
                  {selecteduser == attending_users[key].id ? (
                    <div> {attending_users[key].name} </div>
                  ) : null}
                </div>
              ))}
            </div>
            <h1 style={{ marginBottom: 0 }}>{t("service.title")}</h1>
            <p style={{ marginTop: 12, marginBottom: 30 }}>
              {t("service.select_as_many")}
            </p>
            <div className="form d-flex Service-rating">
              {this.state.services.map((servicesname) => {
                return (
                  <Checkbox
                    handleCheckboxChange={this.handleCheckboxChange}
                    {...servicesname}
                  />
                );
              })}

              <textArea
                placeholder={t("placeholder.service")}
                className="inputtext"
                onChange={this.handlefeedbackChange}
                value={this.state.value}
                style={{ minHeight: 70 }}
              />

              {this.state.addClass || this.state.feedback !== "" ? (
                <div className="nav-button">
                  <button
                    onClick={this.saveAndContinue}
                    className={boxClass.join(" ")}
                    name="next"
                  >
                    {t("button.next")}
                  </button>
                </div>
              ) : (
                <div className="nav-button-skip">
                  <button
                    onClick={this.saveAndContinue}
                    className={boxClass.join(" ")}
                    name="next"
                  >
                    {t("button.skip")}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className={"back-btn"}
            onClick={this.back}
          >
            <img
              alt=""
              src="image/arrow-left.svg"
              style={{ height: 15, width: 15, marginRight: 10 }}
            />
            {t("button.back")}
          </div>
        </div>
      );
    }
  };
  render() {
    return this.mainView();
  }
}

export default withTranslation()(Service);
