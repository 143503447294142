import React from "react";
import RadioButton from "./RadioButton";
import {
  AxiosProvider,
  Request,
  Get,
  Delete,
  Head,
  Post,
  Put,
  Patch,
  withAxios,
} from "react-axios";
import axios from "axios";
import { withTranslation, Trans } from "react-i18next";
import i18n from "./i18n";
import DownArrow from "./assests/down-arrow-lan.png";
import Check from "./assests/check-in.png";

class Basic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      checked: true,
      showDropDown: false,
    };
    this.handleCheck = this.handleCheck.bind(this);
  }

  componentDidMount() {
    console.log("Item...", i18n.language);
    window.scrollTo(0, 0);
  }

  handleCheck(event) {
    this.setState({
      checked: event.target.value,
    });
    this.props.nextStep(event.target.value);

    window.$ratingvalue = event.target.value;
  }

  changeLanguage = (lng) => {
    this.setState({ showDropDown: false });
    i18n.changeLanguage(lng);
    window.location.reload(true);
  };

  mainView = () => {
    const { t, divisionInfo, isStaging } = this.props;

    const companyImg = (divisionInfo && divisionInfo.avatar)
      ? isStaging
        ? divisionInfo.avatar.large.url.replace('dashboard.servicelovers', 'staging-ror.servicelovers')
        : divisionInfo.avatar.large.url
      : null;

    return (
      <div>
        <div className="change-lang">
          <div>
            <div className="change-lang-main">
              <p
                style={{ fontSize: 15, marginRight: 7, cursor: "pointer" }}
                onClick={() =>
                  this.setState({ showDropDown: !this.state.showDropDown })
                }
              >
                {i18n.language.toUpperCase() === "EN-GB" ||
                  i18n.language === "en"
                  ? "EN"
                  : "DA"}
              </p>
              <img
                onClick={() =>
                  this.setState({ showDropDown: !this.state.showDropDown })
                }
                style={{ marginTop: 20, cursor: "pointer" }}
                src={DownArrow}
                alt="down"
                width="12"
                height="12"
              ></img>
            </div>
            {this.state.showDropDown && (
              <div className="drop-down-content">
                <div style={{ height: 82 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: 30,
                      cursor: "pointer",
                    }}
                  >
                    {i18n.language.toUpperCase() === "DK-DA" ||
                      i18n.language === "da" ? (
                        <img
                          style={{
                            marginTop: 20,
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                          src={Check}
                          alt="down"
                          width="12"
                          height="12"
                        ></img>
                      ) : (
                        <div
                          style={{
                            marginTop: 20,
                            height: 12,
                            width: 12,
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                        />
                      )}
                    <p
                      style={{ fontSize: 13, marginTop: 18 }}
                      onClick={() => this.changeLanguage("da")}
                    >
                      DA
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: 30,
                      cursor: "pointer",
                    }}
                  >
                    {i18n.language.toUpperCase() === "EN-GB" ||
                      i18n.language === "en" ? (
                        <img
                          style={{
                            marginTop: 20,
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                          src={Check}
                          alt="down"
                          width="12"
                          height="12"
                        ></img>
                      ) : (
                        <div
                          style={{
                            marginTop: 20,
                            height: 12,
                            width: 12,
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                        />
                      )}
                    <p
                      onClick={() => this.changeLanguage("en")}
                      style={{ fontSize: 13, marginTop: 18 }}
                    >
                      EN
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="inner-wrapper-rating">
          <img src={companyImg} style={{ width: '25%', marginBottom: 30 }} />
          <h1>{t("rating.title")}</h1>
          <div className="form d-flex">
            <RadioButton
              name="rating"
              changed={this.handleCheck}
              id="1"
              isSelected={this.state.checked === 1}
              label={<img alt="" src="image/@1xsmiley-1.svg" />}
              value="1"
            />

            <RadioButton
              name="rating"
              changed={this.handleCheck}
              id="2"
              isSelected={this.state.checked === 2}
              label={<img alt="" src="image/@1xsmiley-2.svg" />}
              value="2"
            />
            <RadioButton
              name="rating"
              changed={this.handleCheck}
              id="3"
              isSelected={this.state.checked === 3}
              label={<img alt="" src="image/@1xsmiley-3.svg" />}
              value="3"
            />
            <RadioButton
              name="rating"
              changed={this.handleCheck}
              id="4"
              isSelected={this.state.checked === 4}
              label={<img alt="" src="image/@1xsmiley-4.svg" />}
              value="4"
            />
            <RadioButton
              name="rating"
              changed={this.handleCheck}
              id="5"
              isSelected={this.state.checked === 5}
              label={<img alt="" src="image/@1xsmiley-5.svg" />}
              value="5"
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.mainView();
  }
}

export default withTranslation()(Basic);
